import axios from 'axios';
import router from '../router/index.js'
import { Loading, Message } from 'element-ui'
import isPlainObject from 'lodash/isPlainObject'  //检查是否是普通对象
import qs from 'qs'
let loading

import utilMd5 from "../utils/md5.js"
import {randomString} from "../utils/util"
let signtoken = '8c8a91d869d3b8af0e4ffcb678c67e3c'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers['Content-Type'] = 'application/json';
if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = '/mh/api';
} else {
  axios.defaults.baseURL = window._config.axiosConfig.baseURL2
}

function startLoading () {
  //开始加载动画
}
function endLoading () {
  //结束加载动画
}

//http request拦截器 添加一个请求拦截器
axios.interceptors.request.use(function (config) {
  startLoading()
  // if (sessionStorage.getItem('token')) {
  //   //  存在将token写入 request header
  //   config.headers['Authorization'] = `${sessionStorage.getItem('token_type')}${sessionStorage.getItem('token')}`
  //   //config.headers.token = `${sessionStorage.getItem('token')}`;
  // }
  if (isPlainObject(config.data)) {
    let random = randomString(6); //每次随机生成6位
    let token = utilMd5.hexMD5(random + signtoken);
    config.data.token = token
    config.data.random = random
    if (/^application\/x-www-form-urlencoded/.test(config.headers.post['Content-Type'])) {
      //把一个参数对象格式化为一个字符串
      config.data = qs.stringify(config.data) 
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

//响应拦截
axios.interceptors.response.use(
  response => {
    if (response.config.responseType == 'arraybuffer' || response.config.responseType == 'blob') {
      return response
    } else {
      if (response.status == '200') {
        endLoading()
        return response
        // if (response.data.code == 1 || response.data.code == '200') {
        //   return response
        // } else {
        //   Message({
        //     type: 'error',
        //     message: response.data.message
        //   })
        // }
      } else {
        endLoading()
        Message({
          type: 'error',
          message: '网络请求异常'
        })
      }
    }
  },
  error => {
    endLoading()
    Message({
      type: 'error',
      message: "您的网络请求异常" + error
    })
    return Promise.reject(error)
  }
)
export default axios
