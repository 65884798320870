<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div class="flex flex-ac">
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20 w-100">系列名称：</span>
            <el-input class="w-250" v-model="retrieveForm.turnName" clearable></el-input>
          </div>
          <div>
            <el-button type="primary" @click="submitSearch">查询</el-button>
          </div>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:img="scope">
          <img :src="scope.row.turnImg" alt="" class="smallImg" v-if="scope.row.turnImg">
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 一番赏详情 -->
    <special-details ref="special-details" @refresh="getTableData"></special-details>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { findTurnList, removeTurn } from '@/network/api'
export default {
  name: 'drawsGood',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
    "special-details": (resolve) => require(["@/views/modules/goods/specialDetails"], resolve),
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
        turnName: '',
      },
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', fixed: 'left', index: this.indexMethod },
        { columnType: 'custom', label: '封面图', prop: 'img', align: 'left', width: '80' },
        { label: '系列名称', prop: 'turnName', align: 'left', 'show-overflow-tooltip': true },
        { label: '价格', prop: 'totalPrice', align: 'left', 'show-overflow-tooltip': true },
        { label: '创建时间', prop: 'createDate', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '150',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList }
          ],
        },
      ],
      tableData: [],
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //新增列表
    addList () {
      this.$refs['special-details'].init()
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      // findTurnList({
      this.$http.post('/turn/findTurnList', {
        currentPage: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        turnName: this.retrieveForm.turnName
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //编辑
    editList (row) {
      this.$refs['special-details'].init(row.id)
    },
    //删除
    deleteList (data) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        // removeTurn({ 
        this.$http.post('/turn/removeTurn', {
          turnId: data.id
        }).then(res => {
          this.getTableData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //跳转详情
    headerRowClick (row) {
      console.log(row)
    },
  },
}
</script>

<style lang="less" scoped>
.dialog-content {
  max-height: 250px;
}
</style>